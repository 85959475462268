import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'

import ContactUs from '../components/ContactUs'
import Layout from '../components/Layout'
import OurProfile from '../components/OurProfile'
import ShorterContent from '../components/ShorterContent'

export default function AboutUs({ data }) {
  const {
    seoMetaTags,
    ourProfile,
    ourMission,
    ourOffices,
    bottomPhoto
  } = data.datoCmsAboutUsPage
  return (
    <Layout>
      <React.Fragment>
        <HelmetDatoCms seo={seoMetaTags}>
          <title>Nordic Asia - Investment Advisory Group | About Us</title>
        </HelmetDatoCms>
        <section className="container center-width prose">
          <ShorterContent>
            <div
              dangerouslySetInnerHTML={{
                __html: ourMission,
              }}
            ></div>
            <div
              dangerouslySetInnerHTML={{
                __html: ourProfile,
              }}
              className="margin-top"
            ></div>
          </ShorterContent>

          <h2>Styrelsen</h2>
          <OurProfile
            members={data.allDatoCmsMember.edges.filter(({ node }) => node.positionType === 'Board' || node.positionType === 'Board and Management')}
            showInterviews={true}
          />

          <h2>Ledning</h2>
          <OurProfile
            members={data.allDatoCmsMember.edges.filter(({ node }) => node.positionType === 'Management' || node.positionType === 'Board and Management')}
            showInterviews={true}
          />

          <h2>Team</h2>
          <OurProfile
            members={data.allDatoCmsMember.edges.filter(({ node }) => node.positionType === 'Team')}
            showInterviews={true}
          />

        </section>
        <section className="container center-width prose">
          <div
            dangerouslySetInnerHTML={{
              __html: ourOffices,
            }}
            className="our-offices margin-bottom"
          ></div>
          <div className="margin-bottom-small">
            Följ oss:
          </div>
          <div>
            {data.datoCmsFooter.socialMedia.map(link => (
              <a
                href={link.target}
                target="_blank"
                rel="noreferrer"
                className="margin-right-small icon"
                key={link.name}
              >
                <img src={link.icon.url} alt={link.name} />
              </a>
            ))}
          </div>
        </section>

        <div className="container center-width">
          <ContactUs data={data.datoCmsContact} />
        </div>

        <Img fluid={bottomPhoto.fluid} className="margin-top" />
      </React.Fragment>
    </Layout>
  )
}


export const query = graphql`
  query {
    datoCmsAboutUsPage {
      ourProfile
      ourOffices
      ourMission
      bottomPhoto {
        fluid(maxWidth: 1800, imgixParams: { fm: "jpg" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      seoMetaTags {
        tags
      }
    }
    allDatoCmsMember(
      filter: { locale: { eq: "en" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          name
          introductionNode {
            childMarkdownRemark {
              html
            }
          }
          id
          title
          photo {
            fixed(width: 300, height: 300, imgixParams: {crop: "faces", ar: "1", fit: "crop"}) {
              ...GatsbyDatoCmsFixed
            }
          }
          interview {
            slug
          }
          positionType
        }
      }
    }
    datoCmsContact {
      ...Contact
    }
    datoCmsFooter {
      ...Footer
    }
  }
`
